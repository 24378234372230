<template>
  <section>
    <div class="btns">
      <v-btn color="primary" @click="modal = true">
        Добавить тэг
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <div class="conteiner">
      <div v-for="tag in allTags" :key="tag.id" class="conteiner__tag active">
        {{ tag.name }}
        <v-btn icon @click="deleteTag(tag.id)">
          <v-icon class="conteiner_icon">mdi-close</v-icon>
        </v-btn>
        <v-btn icon @click="openModalToRedact(tag)">
          <v-icon class="conteiner_icon">mdi-lead-pencil</v-icon>
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="modal" activator="parent" width="60%">
      <v-card class="modal">
        <v-card-title class="modal_title"
          >{{ isRedacting ? "Изменение " : "Добавление " }} тега</v-card-title
        >
        <v-text-field
          v-model="newTag.name"
          :rules="titleRules"
          label="Название"
          required
        ></v-text-field>
        <v-card-actions class="modal_actions">
          <v-btn
            color="primary"
            @click="isRedacting ? changeTag() : addNewTag()"
            >{{ isRedacting ? "Изменить" : "Добавить" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import showMessage from "@/Functions/message";

export default {
  name: "ModerateTags",
  data() {
    return {
      allTags: [],
      modal: false,
      newTag: {
        name: "",
      },
      isRedacting: false,
      titleRules: [
        (value) => {
          if (value) return true;
          return "Введите тэг";
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      STATE: "Reviews/STATE",
    }),
  },
  watch: {
    modal(newVal) {
      if (!newVal) {
        this.isRedacting = false;
        this.newTag = { name: "" };
      }
    },
  },
  mounted() {
    this.setValues();
  },
  methods: {
    ...mapActions({
      GET_MODERATE_TAGS: "Reviews/GET_MODERATE_TAGS",
      EDIT_MODERATE_TAG: "Reviews/EDIT_MODERATE_TAG",
      ADD_MODERATE_TAG: "Reviews/ADD_MODERATE_TAG",
      DELETE_MODERATE_TAG: "Reviews/DELETE_MODERATE_TAG",
    }),
    //Подгружает все тэги
    async setValues() {
      await this.GET_MODERATE_TAGS();
      this.allTags = this.STATE.moderateTags;
    },
    async deleteTag(id) {
      await this.DELETE_MODERATE_TAG(id);
      this.setValues();
    },
    async addNewTag() {
      if (this.newTag.name.length <= 0) {
        return;
      }
      const response = await this.ADD_MODERATE_TAG({
        name: this.newTag.name.trim(),
        deleted: false,
      });
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage();
        this.modal = false;
        this.setValues();
      }
    },
    //Открывает модалку с данными из выбранного тега
    openModalToRedact(tag) {
      this.newTag = { ...tag };
      this.isRedacting = true;
      this.modal = true;
    },
    async changeTag() {
      if (this.newTag.name.length <= 0) {
        return;
      }
      let resp = {
        id: this.newTag.id,
        name: this.newTag.name.trim(),
        deleted: false,
      };
      const response = await this.EDIT_MODERATE_TAG(resp);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage();
        this.modal = false;
        this.setValues();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}
.conteiner {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: left;
  &__header {
    padding-top: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  &__tag {
    padding: 5px 10px;
    border-radius: 10px;
    color: white;
  }
  &_icon:hover {
    color: white !important;
  }
}
.active {
  background-color: green;
}
.deleted {
  background-color: red;
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_title {
    justify-content: center;
  }
  &__text {
    padding: 0;
    margin: 0;
  }
  &_actions {
    justify-content: right;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_title {
    width: 90%;
    text-align: center;
    h1 {
      margin-left: 10%;
    }
  }
}
</style>
